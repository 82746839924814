<template>
  <div class="login-page_wrapper">
      <div class="login-page">
          <h1 class="login-page_header">{{ $t('login') }}</h1>
          <form action="" @submit.prevent="save">
              <div class="input-wrapper">
                  <img class="login-page_icon" src="@/assets/images/user.svg" alt="user">
                  <input v-model="form.UserName" type="email" placeholder="Email" required/>
              </div>
              <div class="input-wrapper">
                  <img class="login-page_icon" src="@/assets/images/password.svg" alt="password">
                  <input v-model="form.Password" type="password" :placeholder="$t('password')" required/>
              </div>
              <div>
                  <router-link to="/forgot-password" class="login-page_link">{{ $t('forgotPassword') }}</router-link>
              </div>
              <div class="btn-login_wrapper">
                  <button type="submit" class="btn-login">{{ $t('signin') }}</button>
              </div>
              <Messages :messages="messages"></Messages>
              <Errors :errors="loginError"></Errors>
          </form>
      </div>
  </div>
</template>

<script>
  import {mapActions,mapGetters} from "vuex";
  import {errors} from "@/mixins/errors"
  import {messages} from "@/mixins/messages"
  import {printArray} from "@/mixins/printArray"
  import Errors from "@/components/Errors"
  import Messages from "@/components/Messages"

  export default {
    name: 'login',
     components: {
        Errors,
        Messages,
     },
      mixins: [
          printArray,
          errors,
          messages,
      ],
      data: () => ({
          form: {
              UserName: '',
              Password: '',
              RememberMe: false
          }
      }),

      computed: {
          ...mapGetters('auth', ['loginErrors']),
          ...mapGetters('applications', ['currentScholarshipTypeId']),
          loginError() {
              return [this.loginErrors]
          }

      },

      mounted() {
          this.clearErrors()
      },

      methods: {
          ...mapActions('auth', ['login']),
          ...mapActions('applications',['getAllScholarships']),
          async save() {
              this.clearErrors()
              const success = await this.login(this.form)
              if(success) {
                  await this.getAllScholarships()
                  if(this.currentScholarshipTypeId) {
                      this.$router.push({name: 'application'})
                  } else {
                      this.$router.push('/not-application')
                  }
              }
          }
      }
  }

</script>

<style lang="scss" scoped>
.login-page {
    padding: 50px;
    width: 400px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    border-radius: 6px;

    &_wrapper {
        height: 100vh;
        background: #F5F8FD;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &_header {
        font-weight: 900;
        font-size: 36px;
        line-height: 52px;
    }

    &_icon {
        position: absolute;
        left: 25px;
    }

    &_link {
        text-decoration: none;
        color: #0045FF;
        font-size: 18px;
        line-height: 32px;
    }

    .input-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 25px 0;
        background: #FFFFFF;
        border: 1px solid #CDD0D9;
        border-radius: 6px;
        position: relative;

        input {
            width: 100%;
            height: 48px;
            padding-left: 55px;
            outline: none;
            border: none;
            font-size: 18px;
            line-height: 32px;
        }
    }
    .btn-login {
        background-color: #0045FF;
        color: #FFFFFF;
        padding: 13px 32px;
        margin-top: 25px;
        border-radius: 6px;
        font-weight: bold;
        font-size: 20px;
        line-height: 34px;
        outline: none;
        border: none;
        cursor: pointer;

        &_wrapper {
            display: flex;
            justify-content: center;
        }
    }
}
</style>